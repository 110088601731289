.overlay {
	position:fixed;
  top:0;
  left:0;
	height:100%;
	width:100%;
	background:rgb(1,1,69, 0.8);
	overflow:auto;
	z-index:3;
}


.modalBox{
  position: fixed;
  min-height: 60%;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  height:auto;
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  padding: 1%;
  z-index:4;
}

.modal {
  width: 95%;
  max-height: 80vh;
  background-color:var(--med);
  display: flex;
  flex-direction: column; 
  box-sizing: border-box;
  align-items: center;
  z-index:5;
  border-radius: 8px;
 
 overflow-y: scroll;
 border: 1px solid var(--light);

  z-index:5;
  
}
@media only screen and (min-width: 1000px){
  .modal{
  width: 80%;
}
}
@media only screen and (min-width: 1200px){
  .modal{
  width: 60%;
}
}
@media only screen and (min-width: 1400px){
  .modal{
  width: 50%;
}
}