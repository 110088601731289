#about{
  padding:0 5%;
 
  min-height:100vh;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

}

.about {
  display:flex;
  margin-bottom: 3%;
}


.about h1{
  font-size:3rem;
  font-family: "Merriweather", serif;
  margin-bottom: 1%;
  padding: 0;
  color: var(--dark)
}
.about h1:nth-child(2){
  color: var(--med)
}
.storyBox{
  padding: 0 5%;
}
.myPhoto{
  margin: 0 2% 2%;
  width: 35%;
  float:left;
  border-radius: 20px;
  box-shadow: 1px 3px 2px;
}

.blurb{
  width:100%;
  font-size: 1.5rem;
  text-align: left;
  margin: 0;
}


@media only screen and (max-width: 767px){
  #about{
    padding: 4% 4% 2%;
  }
  .myPhoto{
    width: 50%;
   
  }

}

@media only screen and (max-width: 468px){
  
  #about h1 {
    font-size: 2.5rem;
  }
  .greet{
    margin-top: 15% ;
    font-size:1.8rem;
    font-weight:bold;
    font-family: "Merriweather", serif;
  }
  .myPhoto{
    width: 98%;
    margin-bottom: 4%;
   
  }
  .blurb{
    font-size: 1.2rem;
    /* text-align: center; */
    padding: 0 2%;
  }
}

