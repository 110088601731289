#contact{
  width:100;
  padding: 1% 0;
  background: var(--dark);
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icons{
  color:var(--light);
  cursor:pointer;
  font-size: 2rem
}

.icons:hover{
  color:red;
}
.exitBox{
  width:100%;
  text-align: right;
}
.closeModal{
  margin-right: 1%;
  font-size:2rem;
  color:var(--dark);
}
.closeModal:hover{
  color: var(--med);
}


.emailForm{
  width: 90%;
  display: flex;
   flex-direction: column;
  align-items: center;
}

.emailForm input, textarea{
  width: 100%;
  font-size: 2rem;
  margin: 1% 0;
  border: 2px solid var(--dark);
  color: var(--dark);
  border-radius: 8px;
}
.sendButton{
  background-color: var(--dark);
  color: var(--light);
  font-size: 1.5rem;
  width: 80%;
  border-radius: 8px;
}
.sendButton:hover{
  color: var(--med)
}


/* Media Queries */
@media only screen and (max-width: 468px){
  .icons{
    font-size: 1.5rem;
  }
  .emailForm{
    width: 90%;
  }
  
}