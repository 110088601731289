#projects {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 15%;
  max-width: 100%;
  height: auto;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(30,144,255,1) 100%);
}

#projects h1{
  font-size: 4rem;
  font-family: "Merriweather", serif;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid var(--med);
  color: var(--dark);
}

.prjBox{
  box-sizing: border-box;
  width: 95%;
  margin: 4% 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-column-gap: 3%;
  grid-row-gap: 3%;
  
  
}

.prjBox .card{
  position: relative; 
  background: var(--dark);
  box-shadow: 0 5px 10px rgba(0,0,0,.8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.imgBox img{
  width:100%;
  object-fit:contain;
  transition: 0.5s;
}

.prjBox .card:hover .imgBox img{
  opacity: 0.2;
  transform: scale(1.2)
}

.prjBox .card .content{
  position: absolute;
  top:50%;
  transform: translateY( -50%);
  opacity: 0;
  width:100%;
  height:100%;
  color:var(--light);
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h2{
  font-size: 1.8rem;
  font-family: "Merriweather", serif;
  margin: 1% 0 0;
  transform: translateY(-50px);
  transition: 0.5s;
} 
.content p{
  font-size: 1.2rem;
  margin: 1%;
  transform: translateY(-40px);
  transition: 0.5s;
}
.prjBox .card:hover .content{
  opacity:1;
  transition-delay: 5ms;
}
.prjBox .card:hover .content h2,
.prjBox .card:hover .content p{
  transform: translateY(0px);
  transition-delay: 1ms;
}


.prjBox .card .content .cardSkills{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  transition: 0.5s;
  transform: translateY(50px)
}

.prjBox .card .content .cardSkills img{
  width: 12%;
  margin: 1% 6%;
}
.prisma{
  border-radius: 8px;
}
.auth0{
  width: 30%;
}
.prjBox .card:hover .content .cardSkills{
  transform: translateY(0px);
  transition-delay: 6ms;
}



.prjBox .card .cardButton{
  position: absolute;
  bottom: 0;
  transform: translateY( -75px);
  backface-visibility : hidden;
  transition: 0.5s;
  margin: 4% 2%;
  font-size: 1.5rem;
  padding:1%;
  background: var(--light);
  color:var(--dark);
  border: 1px solid var(--med);
  border-radius:8px;
  white-space: nowrap;
}

.prjBox .card:hover .cardButton{
  transform: translateY(0px);
  cursor: pointer;
}


/* prj Modal */

.topBox{
  width:100%;
}

.projectTitle{
  background: var(--med);
  color:var(--light);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 0;
  display: flex;
  flex-direction: column;
  
}
.projectTitle h1{
  font-family: "Merriweather", serif;
  margin:0;
  white-space: nowrap;
  font-size: 1.8rem;
}
/* Slideshow container */
.slideShowContainer {
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.slides{
  display:none;
}
/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

.bottomBox{
  width: 100%;
  border-radius: 0 0 8px 8px;
}
.goals{
  background: var(--dark);
  color: var(--med);
  margin: 0;
  padding: 1% 2%;
}
.goals h3 {
  margin: 1% 0;
}
.goals h3{
  font-size: 1.5rem;
}
.prjLinks{
  width: 100%;
  font-size: 2.5rem;
  padding-top: 1%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0 0 8px 8px;
}

.prjLinks a{
  text-decoration: none;
  color: var(--dark);
  margin:1%;
}

.prjLinks a:hover{
  color: var(--light);
}

.exitModal{
  color: var(--light);
}
.exitModal:hover{
  color: red;
}

/* Media Queries */
@media only screen and (min-width: 400px){
  #projects{
    padding: 0 0 13%;
  }
  .projectTitle h1{
    font-size: 2.2rem;
  }
  .content h2{
    font-size: 2.1rem;
  }
  .content p {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 600px){
  
  #projects{
    padding: 0 ;
    margin: 0;
  }
  .prjBox{
    
    margin: 4% 0 8%;
  }
  .prjButton{
    width: 50%;
    font-size: 1rem;
  }
  .closeBox h1{
    font-size:1.5rem
  }
  .projectTitle h1{
    font-size: 2.4rem;
  }
  .project h1{
    font-size: 1.5rem
  }
  .prjLinks{
    font-size: 2rem;
  }
}
@media only screen and (min-width: 700px){
  .prjBox{
    
    margin: 4% 0 6%;
  }
}
@media only screen and (min-width: 800px){
  #projects h1{
    font-size: 3rem;
  }
  .prjBox{
    margin: 2.5% 0 ;
  }
}
@media only screen and (min-width: 1000px){
  
  .prjBox{
    margin: 4% 0 10%;
  }
}

@media only screen and (min-width: 1100px){
  #projects h1{
    font-size: 4rem;
  }
  .prjBox{
    margin: 10% 0 ;
  }
}

@media only screen and (min-width: 1300px){
  .prjBox{
    margin: 8% 0 20%;
  }
}
@media only screen and (min-width: 1400px){
  .prjBox{
    margin: 8% 0 ;
  }
}